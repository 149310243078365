import React from 'react';

export default function ItemInput({
    name = '',
    label = 'Label',
    className = '',
    ...props
}) {
    return (

        <div className={'form-floating ' + className}>
            <input type="text" className="form-control form-control-sm" name={name} id="item_name" {...props} />
            <label htmlFor="floatingInput">   {label} (g)</label>
        </div>

    );
}

import React, { useState } from 'react';

const Pills = ({ items, selectedItems, setSelectedItems }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handlePillClick = (_id) => {
        if (selectedItems.includes(_id)) {
            setSelectedItems(selectedItems.filter(item => item !== _id));
        } else {
            setSelectedItems([...selectedItems, _id]);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredItems = items.filter(item => 
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortedItems = filteredItems.sort((a, b) => {
        if (selectedItems.includes(a._id) && !selectedItems.includes(b._id)) {
            return -1;
        }
        if (!selectedItems.includes(a._id) && selectedItems.includes(b._id)) {
            return 1;
        }
        return 0;
    });

    return (
        <div className="bg-white p-3">
            <input
                type="text"
                className="form-control mb-3"
                placeholder="Search items"
                value={searchQuery}
                onChange={handleSearchChange}
            />
            {sortedItems.map(item => (
                <button
                    key={item._id}
                    type="button"
                    className={`btn py-2 px-4 m-3 ${selectedItems.includes(item._id) ? 'btn-dark' : 'btn-outline-secondary text-secondary'}`}
                    onClick={() => handlePillClick(item._id)}
                >
                    {item.name}
                </button>
            ))}
        </div>
    );
};

export default Pills;

import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import authService from '../../api/auth_service';

export default function Header() {

    const navigate = useNavigate();

    const handleLogOut = async (event) => {
        event.preventDefault();
        authService.logout();
        navigate("/login");
    };

    return (
        <div>
            <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
                <div className="col-md-3 mb-2 mb-md-0">
                    <NavLink to="/" className="d-inline-flex link-body-emphasis text-decoration-none">
                        <img width="40" height="32" role="img" src="../Logo.png" className="img-thumbnail bi" alt="..."></img>
                        <small className='px-2'>Diet Tribe Admin</small>
                    </NavLink>
                </div>

                <div>
                    <a href="#" className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="" alt="mdo" width="24" height="24" className="rounded-circle" />
                    </a>
                    <ul className="dropdown-menu text-small shadow" data-popper-placement="top-start">
                        <li><a className="dropdown-item" onClick={handleLogOut}>Sign out</a></li>
                    </ul>
                </div>
            </header>
        </div>
    )
}
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function SideNav({ className }) {
    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary position-fixed">
            <NavLink to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <span className="fs-4">Menu</span>
            </NavLink>
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">
                <li>
                    <NavLink to="/" className="nav-link link-body-emphasis bg-white text-dark">
                        Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/nutrients" className="nav-link link-body-emphasis bg-white text-dark">
                        Nutrients
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/items" className="nav-link link-body-emphasis bg-white text-dark">
                        Items
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to="/chef-actions" className="nav-link link-body-emphasis bg-white text-dark">
                        Chef actions
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to="/recipe-unit-scheme" className="nav-link link-body-emphasis bg-white text-dark">
                        Recipe unit schemes
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dishes" className="nav-link link-body-emphasis bg-white text-dark">
                        Dishes
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/factor-categories" className="nav-link link-body-emphasis bg-white text-dark">
                        Factors
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}
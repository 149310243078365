// authService.js
import axios from 'axios';
import { BaseURL } from './service_variables';

const authService = {
  login: async (username, password) => {
    try {
      var formdata = new FormData();
      formdata.append("username", username);
      formdata.append("password", password);


      const response = await axios.post(
        `${BaseURL}/token/`,
        formdata,
        {
          timeout: 15000,
          withCredentials: false,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

      // Assuming the server returns a token upon successful login
      const token = response.data.access_token;

      // Store the token in localStorage or a state management solution (e.g., Redux)
      window.localStorage.setItem('token', token);

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },

  // You might also include a logout function here
  logout: () => {
    localStorage.clear()
  }
};

export default authService;
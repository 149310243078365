import React from 'react';
import CreateRecipeUnitSchemeForm from '../../components/Form/recipe-unit-scheme/create';


export default function CreateRecipeUnitScheme() {
    return (
        <div>
            <CreateRecipeUnitSchemeForm purpose="Create"/>
        </div>
    )
}
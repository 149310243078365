import Footer from '../navigation/footer';
import Header from '../navigation/header';
import SideNav from '../navigation/side_navigation';
import React from 'react';

import { Outlet} from "react-router-dom";

export default function Layout(...props) {

    return (
        <div>
            <div className="">
                <Header />
                <div className='row'>
                    <div className='col-2'>
                        <SideNav />
                    </div>
                    <div className='col-10'>
                        <Outlet />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

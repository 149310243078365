import React from 'react';
import CreateDishForm from '../../components/Form/dishes/create';
import RecipeForm from '../../components/Form/dishes/recipe';


export default function CreateDish() {
    return (
        <div>
            {/*<CreateDishForm purpose="Create" />*/}
            <CreateDishForm purpose="Create" />
        </div>
    )
}
import React from 'react';
import Intro from '../components/intro/intro';

export default function Index() {

    return (
        <div>
            <Intro />
        </div>
    )
}
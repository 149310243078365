import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateFactorForm from '../../components/Form/factors/update';



export default function ViewFactor() {
    const { id } = useParams();

    return (
        <div>
            <UpdateFactorForm purpose="Update" id={id} />
        </div>
    )
}
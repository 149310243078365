import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import factorCategoryService from '../../../api/factors_category_service';
import { NavLink } from 'react-router-dom';
import TableWrapper from '../../table/table_wrapper';
import CreateFactorForm from '../factors/create';
import factorService from '../../../api/factors_service';
import FactorTable from './table';
import foodItemsService from '../../../api/food_items_service';
import factorScoreService from '../../../api/factor_scores_service';

export default function UpdateFactorForm({ purpose, id,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [language, setLanguage] = useState("");
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [factors, setScoreData] = useState([]);
    const [items, setItems] = useState([]);
    const [itemsSet, setItemsSet] = useState([]);
    const [itemsUnset, setItemsUnset] = useState([]);

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getFactor = async () => {
        try {
            const getData = await factorService.getFactor(id);
            if (getData) {
                setName(getData.names[0].name);
                setLanguage(getData.names[0].language_id);
                setDescription(getData.description);
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    const getItemsData = async () => {
        try {
            const getData = await foodItemsService.getFoodItems();
            const getScoreData = await factorScoreService.getFactorScores();
            const scoreData = getScoreData?.filter(data => { if (id === data.factor_id) { return true } else { return false } }) 


            if (getData) {
                getData.sort(function(a, b) {
                    // Convert names to lowercase for case-insensitive sorting
                    let nameA = a.names[0].name.toLowerCase();
                    let nameB = b.names[0].name.toLowerCase();
                    
                    if (nameA < nameB) {
                        return -1; // nameA comes before nameB
                    }
                    if (nameA > nameB) {
                        return 1; // nameB comes before nameA
                    }
                    return 0; // names are equal
                });
                setItems(getData);
            }

            if (scoreData) {
                
            }

            if (getData && scoreData) {
                const unsetItemHolder = getData.filter(data => {
                    if (scoreData && scoreData.some(score => (score.item_id == data._id))) {
                        return false;
                    } else {
                        return true;
                    }
                });

                var cunsetItemHolder = [];
                unsetItemHolder?.map(data => {
                    var container = { "score": "", "name": data.names[0].name, "id": data._id, "socre_id": ""}
                    cunsetItemHolder.push(container)
                })

                var setItemHolder = [];
                scoreData?.map(score => {
                    getData?.map(
                        item => {
                            if (item._id == score.item_id) {
                                var container = { "score": score.score, "name": item.names[0].name, "id": item._id, "score_id": score._id }
                                setItemHolder.push(container)
                                return
                            }
                        }
                    )
                })
                setItemsUnset(cunsetItemHolder);
                setItemsSet(setItemHolder);
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const names = { 'language_id': language, 'name': name }

        /* if (validate() === true) {
            console.log("Failed" + nameValidator + macroValidator)
            return
        }*/
        setDisabled(true);

        try {
            const createData = await factorCategoryService.updateFactorCategory(names, description, id);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    useEffect(() => { getFactor() }, []);
    useEffect(() => { getItemsData() }, []);


    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Factor Category Created</Toast.Body>
                </Toast>
            </div>

            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Factor</button>
                    <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Score {name}</button>
                    <button className="nav-link" id="nav-filled-tab" data-bs-toggle="tab" data-bs-target="#nav-filled" type="button" role="tab" aria-controls="nav-filled" aria-selected="false">Already Scored {name}</button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0"><form className='bg-light p-3'>
                    <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Factor</h1>
                    <div className="form-floating required py-4">
                        <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} value={name} id="name" onChange={(e) => setName(e.target.value)} />
                        <label className="control-label" htmlFor="floatingInput">Factor Name<small className='text-danger'>*</small></label>
                        {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                    </div>
                    <div className="form-floating py-4">
                        <textarea type="text" className="form-control" value={description} id="description" onChange={(e) => setDescription(e.target.value)} />
                        <label className='control-label' htmlFor="floatingInput">Factor Description</label>
                    </div>

                    <div className='row justify-content-center'>
                        <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                    </div>
                </form>
                </div>



                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex="0">
                    <h2 className='text-center py-4'>{name}</h2>
                    <FactorTable list={itemsUnset} factor_id={id} />
                    {factors.length > 0 && < TableWrapper data={factors} link="/view-factor" />}
                </div>


                <div className="tab-pane fade" id="nav-filled" role="tabpanel" aria-labelledby="nav-filled-tab" tabIndex="0">
                    <h2 className='text-center py-4'>{name}</h2>
                    <FactorTable list={itemsSet} factor_id={id} />
                    {factors.length > 0 && < TableWrapper data={factors} link="/view-factor" />}
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import CreateChefActionForm from '../../components/Form/chef-action/create';


export default function CreateChefAction() {
    return (
        <div>
            <CreateChefActionForm purpose="Create"/>
        </div>
    )
}
import React from 'react';
import NutrientForm from '../../components/Form/nutrient/create';


export default function CreateNutrient() {
    return (
        <div>
            <NutrientForm purpose="Create"/>
        </div>
    )
}
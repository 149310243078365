import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import nutrientService from '../../api/nutrients_service';


export default function Nutrients() {
    const [nutrients, setNutrient] = useState([]);

    const getNutrientsData = async () => {
        try {
            const getData = await nutrientService.getNutrients();
            if (getData) { setNutrient(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getNutrientsData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-nutrient" className="btn btn-dark">
                    Add Nutrient
                </NavLink>
            </div>
            {nutrients.length > 0 && < TableWrapper data={nutrients} link="/view-nutrient" />}
        </div>
    );
}
import { React, useState } from 'react';
import authService from '../api/auth_service';
import Header from '../components/navigation/header';
import { useNavigate } from "react-router-dom";

export default function Login() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        setDisabled(true);
        try {
            const loginData = await authService.login(username, password);
            if (loginData) {
                navigate("/");
            }
        } catch (error) {
            setDisabled(false);
            setLoginError(true)
        }
    };

    return (
        <div>
            <Header />
            <main className="form-signin m-auto container col-md-6 mh-4 px-3">
                <form>
                    <h1 className="h3 mb-3 fw-normal py-4">Please sign in</h1>
                    {loginError && (
                        <div class="alert alert-danger" role="alert">
                            Login credentials are incorrect
                        </div>)
                    }

                    <div className="form-floating py-4">
                        <input type="username" className="form-control" id="floatingInput" placeholder="name@example.com"
                            value={username} onChange={(e) => setUsername(e.target.value)}
                        />
                        <label htmlFor="floatingInput">Username</label>
                    </div>
                    <div className="form-floating py-4">
                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                            value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='true'
                        />
                        <label htmlFor="floatingPassword">Password</label>
                    </div>

                    <div className="form-check text-start my-3">
                        <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Remember me
                        </label>
                    </div>
                    <button className="btn btn-dark w-100 py-4" onClick={handleLogin} disabled={disabled}>Sign in</button>
                </form>
            </main>

        </div>
    )
}
import React, { useState, useEffect } from 'react';
import ItemInput from './input';
import { Toast } from 'react-bootstrap';
import nutrientService from '../../../api/nutrients_service';
import foodItemsService from '../../../api/food_items_service';
import recipeUnitSchemeService from '../../../api/recipe_unit_scheme_service';

export default function ItemForm({ purpose,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [allNutrients, setAllNutrients] = useState();
    let nutrientList;
    const [nutrient, nutrientState] = useState(nutrientList);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const language = "659b6f8f48e3e48cea5bbccf";
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [nutrients, setAllNutrientsQuantity] = useState([]);
    const [recipeScheme, setRecipeScheme] = useState([]);
    const [perUnitQuantity, setPerUnitQuantity] = useState("");
    const [measuringUnit, setMeasuringUnit] = useState("");

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getNutrientsData = async () => {
        try {
            const getData = await nutrientService.getNutrients();
            if (getData) { setAllNutrients(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    const getRecipeSchemeData = async () => {
        try {
            const getData = await recipeUnitSchemeService.getRecipeUnitSchemes();
            if (getData) { setRecipeScheme(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    const handleNutrientsChange = (id, event) => {
        const singleNutrient = { nutrient_id: id, quantity: event.target.value, unit: "g" }
        let newInputValues = nutrients.filter(data => data.nutrient_id !== id)
        if (event.target.value !== "") {
            newInputValues = [...newInputValues, singleNutrient];
        }
        setAllNutrientsQuantity(newInputValues);
    }

    const handleCreate = async (event) => {
        event.preventDefault();
        const names = { 'language_id': language, 'name': name }

        console.log("name" + names)
        console.log("description" + description)
        console.log("language_id" + names.language_id)
        //console.log("name" + name)
        console.log(nutrients)


        /* if (validate() === true) {
            console.log("Failed" + nameValidator + macroValidator)
            return
        }*/
        setDisabled(true);

        try {
            const createData = await foodItemsService.createFoodItem(names, description, measuringUnit, perUnitQuantity, nutrients);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    useEffect(() => { getNutrientsData() }, []);
    useEffect(() => { getRecipeSchemeData() }, []);
    useEffect(() => { nutrientState(allNutrients) }, [allNutrients]);


    nutrientList = allNutrients

    const search = event => {
        const input = event?.target?.value?.toLowerCase();
        let filteredList = nutrientList;

        filteredList = nutrientList?.filter(data => {
            let result = false;
            if (data?.toLowerCase()?.includes(input) && result === false) {
                result = true;
            }
            return result;
        });

        nutrientState(filteredList);
    };

    //console.log(nutrients)
    const list = nutrient?.map(data => {
        return <ItemInput className='col-lg-3 col-md-4 col-6' label={data["name"]} key={data["_id"]} onChange={(event) => handleNutrientsChange(data["_id"], event)} />
    })

    const listOfMeasuringUnits = recipeScheme?.map(data => {
        return <option key={data["_id"]} value={data["_id"]}>{data.names[0].name}</option>
    })

    /*   list?.map(data => {
          console.log(data.value)
      })
   */
    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Food Item Created</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Create Food Item</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} id="name" onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Item Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Item Description</label>
                </div>
                <div className='row'>
                    <div className="form-floating py-4 col-8">
                        <select class="form-select" aria-label="Default select example" onChange={(e) => setMeasuringUnit(e.target.value)}>
                            <option selected>Unit of Measurement</option>
                            {listOfMeasuringUnits}
                        </select>
                    </div>

                    <div className="form-floating py-4 col-4">
                        <textarea type="text" className="form-control" id="description" value={perUnitQuantity} onChange={(e) => setPerUnitQuantity(e.target.value)} />
                        <label className='control-label' htmlFor="floatingInput">Amount</label>
                    </div>
                </div>
                <hr />
                <input className="form-control py-2 my-4 form-control-sm" type="search" placeholder="Search Items" aria-label="Search" onChange={search} />
                <div className='row py-4 grid row-gap-3'>

                    {list}
                </div>
                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleCreate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}

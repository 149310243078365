// TextEditor.js
import React, { useState } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';

const TextEditor = () => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <button className="btn btn-primary m-1" onClick={(e) => {e.preventDefault(); toggleBlockType('header-one');}}>H1</button>
        <button className="btn btn-primary m-1" onClick={(e) => {e.preventDefault(); toggleBlockType('header-two');}}>H1</button>
        <button className="btn btn-primary m-1" onClick={(e) => {e.preventDefault(); toggleBlockType('header-three');}}>H3</button>
        <button className="btn btn-primary m-1" onClick={(e) => {e.preventDefault(); toggleInlineStyle('BOLD');}}>Bold</button>
        <button className="btn btn-primary m-1" onClick={(e) => {e.preventDefault(); toggleInlineStyle('ITALIC');}}>Italic</button>
        <button className="btn btn-primary m-1" onClick={(e) => { e.preventDefault(); toggleInlineStyle('UNDERLINE'); }}>Underline</button>
        <button className="btn btn-primary m-1" onClick={(e) => {e.preventDefault(); toggleBlockType('unordered-list-item');}}>UL</button>
        <button className="btn btn-primary m-1" onClick={(e) => {e.preventDefault(); toggleBlockType('ordered-list-item');}}>OL</button>
      </div>
      <div style={{ border: '1px solid #ccc', padding: '10px', minHeight: '200px' }}>
        <Editor 
          editorState={editorState} 
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState} 
        />
      </div>
    </div>
  );
};

export default TextEditor;

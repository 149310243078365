import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import factorCategoryService from '../../../api/factors_category_service';

export default function CreateFactorCategoryForm({ purpose,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const language = "659b6f8f48e3e48cea5bbccf";
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");

    useEffect(() => {
        setShowToast(false);
    }, []);


    const handleCreate = async (event) => {
        event.preventDefault();
        const names = { 'language_id': language, 'name': name }

        console.log("name" + names)
        console.log("description" + description)
        console.log("language_id" + names.language_id)


        /* if (validate() === true) {
            console.log("Failed" + nameValidator + macroValidator)
            return
        }*/
        setDisabled(true);

        try {
            const createData = await factorCategoryService.createFactorCategory(names, description);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Factor Category Created</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Create Factor Category</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} id="name" onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Factor Category Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Factor Category Description</label>
                </div>
                
                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleCreate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}

import React, { useState } from 'react';

const Tabs = ({ tabs, toast }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    return (
        <div>
            {/* <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={toast.show} onClose={toast.onClose} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'></Toast.Header>
                    <Toast.Body>{toast.message}</Toast.Body>
                </Toast>
            </div> */}

            <nav>
                <div className="nav nav-tabs" role="tablist">
                    {tabs.map((tab) => (
                        <button
                            key={tab.id}
                            className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={tab.id}
                            aria-selected={activeTab === tab.id}
                            onClick={() => setActiveTab(tab.id)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </nav>
            <div className="tab-content">
                {tabs.map((tab) => (
                    <div
                        key={tab.id}
                        className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
                        id={tab.id}
                        role="tabpanel"
                        aria-labelledby={tab.id}
                        tabIndex="0"
                    >
                        {tab.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tabs;


import React, { useState } from 'react';
import { Link } from 'react-router-dom';


export default function TableWrapper({ data = [], link = "#" }) {

    //Handle Search
    const [dataStateHolder, dataState] = useState(data);

    const search = event => {
        const input = event?.target?.value?.toLowerCase();
        let filteredList = data;

        filteredList = data?.filter(item => {
            let result = false;
            if (item?.name?.toLowerCase()?.includes(input) && result === false) {
                result = true;
            }
            return result;
        });

        dataState(filteredList);
    };

    return (
        <div>

            <h1 className="display-6"></h1>
            <form className="w-100 fluid" role="search">
                <input className="form-control" type="search" placeholder="Search" aria-label="Search" onChange={search} />
            </form>

            <table className="table table-hover table-borderless">
                <tbody>
                    {Array.isArray(dataStateHolder) && (dataStateHolder.length > 0) ? (
                        dataStateHolder?.map((item, index) => (
                            <tr key={index}>
                                <td key={item._id}><Link to={`${link}/${item._id}`} className='py-0'> {item.name} </Link></td>
                            </tr>
                        ))
                    ) : (
                        <tr className="text-center py-4">
                            <td colSpan="2">No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}